import { get } from 'lodash';
import { createSelector } from 'reselect';
import { isLoaded } from '../../common/requestStatuses';

const timeslotsSelector = state => get(state, 'timeslots', {});
const locationIdSelector = (state, { locationId }) => locationId;

export const locationTimeslotsSelector = createSelector(
  timeslotsSelector,
  locationIdSelector,
  (timeslots, locationId) => {
    if (timeslots.location.id !== locationId) return [];

    return timeslots.location.entities;
  }
);

export const locationTimeslotsLoadedSelector = createSelector(
  timeslotsSelector,
  locationIdSelector,
  (timeslots, locationId) => {
    if (timeslots.location.id !== locationId) return false;

    return isLoaded(timeslots.location.requestStatus);
  }
);
