import { get, find, range } from 'lodash';
import { createSelector } from 'reselect';
import { isLoaded } from '../../common/requestStatuses';
import { DAYS_IN_WEEK_NUM } from '../TimeslotsEditor/constants';

export const organizationTimeslotsLoadedSelector = state => isLoaded(get(state, 'timeslots.organization.requestStatus'));

const organizationTimeslotEntitiesSelector = state => get(state, 'timeslots.organization.entities', {});

export const organizationTimeslotDaysSelector = createSelector(
  organizationTimeslotEntitiesSelector,
  organizationTimeslotsLoadedSelector,
  ({ defaultTimeslots, timeslotOverrides }, loaded) => {
    if (!loaded) return [];

    const organizationTimeslotDays = range(0, DAYS_IN_WEEK_NUM).map(dayOfWeekNum => (
      defaultTimeslots.map((defaultTimeslot) => {
        const override = find(timeslotOverrides, {
          dayOfWeekNum,
          baseTimeslotUuid: defaultTimeslot.baseTimeslotUuid,
        });
        return {
          ...defaultTimeslot,
          ...override || {},
          type: null,
          dayOfWeekNum,
        };
      })
    )).flat();

    return organizationTimeslotDays;
  });

export const organizationDefaultTimeslotsSelector = state => (
  organizationTimeslotEntitiesSelector(state).defaultTimeslots);
