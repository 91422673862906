import { differenceWith, isMatch, pick } from 'lodash';
import { TimeslotConstants } from '../constants';
import { RequestActions, RequestConstants } from '../../request';
import { actions as unsavedChangesActions } from '../../unsaved-changes';
import { organizationTimeslotsSelectors } from '../selectors';

const { organizationTimeslotDaysSelector } = organizationTimeslotsSelectors;

const {
  ORGANIZATION_TIMESLOTS_URL: organizationTimeslotsUrl,
  locationTimeslotsUrl,
} = TimeslotConstants;

export const areTimeslotAttributesEqual = (timeslot1, timeslot2) => isMatch(
  timeslot1, pick(timeslot2, TimeslotConstants.TIMESLOT_ATTRIBUTES));

export const TimeslotActions = {
  fetchDefaultTimeslots(organizationId) {
    return RequestActions.request({
      key: TimeslotConstants.TIMESLOTS_LOADED,
      method: RequestConstants.GET,
      url: organizationTimeslotsUrl(organizationId),
    });
  },
  fetchOrganizationTimeslots(organizationId) {
    return RequestActions.request({
      key: TimeslotConstants.FETCH_ORGANIZATION_TIMESLOTS,
      method: RequestConstants.GET,
      url: organizationTimeslotsUrl(organizationId),
    });
  },
  fetchLocationTimeslots(locationId) {
    return RequestActions.request({
      key: TimeslotConstants.FETCH_LOCATION_TIMESLOTS,
      method: RequestConstants.GET,
      url: locationTimeslotsUrl(locationId),
      content: { locationId },
    });
  },
  updateLocationTimeslots: timeslots => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    return dispatch({
      type: TimeslotConstants.UPDATE_LOCATION_TIMESLOTS,
      timeslots,
    });
  },
  updateOrganizationTimeslots: newTimeslots => (dispatch, getState) => {
    dispatch(unsavedChangesActions.touched());

    const existingTimeslots = organizationTimeslotDaysSelector(getState());
    const timeslotOverrides = differenceWith(
      newTimeslots, existingTimeslots, areTimeslotAttributesEqual);

    return dispatch({
      type: TimeslotConstants.UPDATE_ORG_TIMESLOT_OVERRIDES,
      timeslotOverrides,
    });
  },
  updateDefaultOrganizationTimeslots: timeslots => (dispatch) => {
    dispatch(unsavedChangesActions.touched());

    return dispatch({
      type: TimeslotConstants.UPDATE_DEFAULT_ORGANIZATION_TIMESLOTS,
      timeslots,
    });
  },
};
