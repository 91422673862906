export const ADD_USER_URI = 'users/new';
export const INVITE_USER_URI = 'user_invitations/new';
export const organizationUsersUrl = organizationId => `/api/organizations/${organizationId}/user_list/`;
export const CREATE_CUSTOM_ROLE_KEY = '@custom_roles create_role';
export const UPDATE_CUSTOM_ROLE_KEY = '@custom_roles update_role';
export const CLEAR_ROLE_PANEL = '@custom_roles clear_role_panel';
export const SET_EDITING_ROLE = '@custom_roles set_editing_role';
export const SET_ROLE_NAME = '@custom_roles set_role_name';
export const SET_ROLE_DESCRIPTION = '@custom_roles set_role_description';
export const TOGGLE_ROLE_PERMISSION = '@custom_roles toggle_role_permission';
export const HTTP_CREATED = 201;
export const NOT_FOUND = 404;
export const FAILED_RESPONSE = 500;
export const USERS_FETCH_KEY = 'users.get';
export const ARCHIVE_USER = 'archiveUser';
export const RESTORE_USER = 'restoreUser';
export const RESEND_INVITE_USER = 'inviteUser';
export const REMOVE_PERMITTED_ORGANIZATION_USER = 'remove_permitted_organization';
export const tabTypes = { USERS: 'tab.users', ROLES: 'tab.roles' };
export const ALL_SITES = 'All Sites';
export const restoreUserUrl = userId => `/api/user/${userId}/restore`;
export const getUserUrl = (organizationId, userId) => `/organizations/${organizationId}/users/${userId}/edit`;
export const archiveUserUrl = userId => `/api/user/${userId}/archive`;
export const inviteUserUrl = userId => `/api/user/${userId}/invite`;
export const shadowUserUrl = userId => `/shadow/${userId}`;
export const userRemoveApiUrl = (userId, organizationId) => `/api/user/${userId}/remove_permitted_organization/${organizationId}`;
export const DEMO_STATUS = 'demo';
